/*
 * @Author: N0ts
 * @Date: 2022-11-23 11:06:09
 * @Description: 主题
 * @FilePath: /vue/src/utils/themeColor.ts
 * @Mail：mail@n0ts.top
 */

import data from "@/data/data";
import local from "@/utils/localData/local";

export default {
    /**
     * 主题色修改
     * @param color 主题颜色
     */
    change(color?: string) {
        if (color) {
            data.saveData.themeColor = color;
        }
        document.documentElement.style.setProperty(
            "--themeColor",
            data.saveData.themeColor
        );
        local.save();
    }
};
