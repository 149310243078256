/*
 * @Author: N0ts
 * @Date: 2022-11-21 14:44:39
 * @Description:
 * @FilePath: /vue/src/utils/hitokoto.ts
 * @Mail：mail@n0ts.top
 */
import http from "@/utils/http/axios";
import data from "@/data/data";
import notify from "@/utils/notify/notify";

export default function () {
    // 一言是否展示
    if (!data.saveData.hitokotoShow) {
        return;
    }

    // 一言类型选择
    let type = "?";
    data.saveData.hitokotoIndex.forEach((item: any) => {
        type += item == "all" ? "" : `c=${item}&`;
    });

    // 类型判断
    http.get("https://v1.hitokoto.cn" + type)
        .then((res: any) => {
            // hitokoto.value = `「 ${res.hitokoto} 」- ${res.from}`;
            data.hitokoto = `${res.hitokoto}`;
        })
        .catch((err) => {
            console.log(err);
        });
}
