/*
 * @Author: N0ts
 * @Date: 2022-01-13 11:43:22
 * @LastEditTime: 2022-12-01 09:49:07
 * @Description: 更新日志
 * 1：新增；2：修改；3：修复；4：移除；5：重构；
 * @FilePath: /vue/src/data/log.ts
 * @Mail：mail@n0ts.top
 */

export default [
    {
        time: "2022-8-19",
        log: [
            "修改；修改了多数样式引起的问题",
            "重构；主题色功能重构完成",
            "重构；背景图重构完成",
            "重构；设置按钮位置重构完成",
            "重构；毛玻璃效果配置重构完成"
        ]
    },
    {
        time: "2022-8-16",
        log: [
            "新增；新增底部文案是否展示",
            "新增；新增一言是否展示",
            "重构；使用 Vue3 + Ts 重构完成"
        ]
    },
    {
        time: "2022-7-25",
        log: [
            "新增；新增搜索结果时支持 Control + 数字键 快捷跳转结果",
            "新增；新增搜索框聚焦时 TAB 键切换搜索引擎",
            "修改；网址导航样式修改"
        ]
    },
    {
        time: "2021-1-10",
        log: [
            "新增；新增版本更新提示",
            "修改；部分按钮图标修改",
            "修改；天气样式修改",
            "修改；搜索框样式修改",
            "修复；当登录权限过期后自动注销"
        ]
    },
    {
        time: "2021-1-7",
        log: [
            "新增；用户数据现已可以同步在码云的自己仓库中",
            "修改；重置数据改为用户设置重置，用户信息需要点击注销",
            "修复；修复了天气悬浮展示位置错误问题"
        ]
    },
    {
        time: "2021-12-30",
        log: [
            "新增；新增点击 bing 壁纸介绍即可跳转壁纸详情",
            "新增；「主题色设置」预增了多种默认颜色一键切换",
            "新增；新增哔哩哔哩的搜索",
            "新增；搜索引擎现已可以删除啦",
            "修改；文案颜色修改为黑灰",
            "修改；上下键切换选项代码优化",
            "修改；壁纸切换代码重构，优化性能",
            "修改；移动端网址导航 hover 颜色改变取消",
            "修改；网址展开图标动画修改",
            "修复；切换 bing 壁纸介绍显示错误问题",
            "修复；网址导航联动滚动位置错误问题",
            "重构；日志展示重构",
            "重构；天气更换为和风天气"
        ]
    },
    {
        time: "2021-12-29",
        log: [
            "新增；新增搜索设置菜单",
            "修改；点击一言即可快速复制",
            "修复；修复一言隐藏失败问题",
            "重构；菜单分类重构",
            "重构；搜索引擎删除按钮样式重写"
        ]
    },
    {
        time: "2021-12-1",
        log: ["新增；新增毛玻璃开关", "修改；CSS 动画优化", "重构；分类样式重构"]
    },
    {
        time: "2021-9-26",
        log: [
            "新增；新增一言类型选择功能",
            "修复；时间显示错误问题修复",
            "重构；部分代码重构"
        ]
    },
    {
        time: "2021-8-4",
        log: [
            "新增；新增设置按钮位置修改",
            "新增；新增版本差异数据更新",
            "修改；优化了部分逻辑问题"
        ]
    },
    {
        time: "2021-8-3",
        log: [
            "新增；新增主题颜色设置",
            "新增；新增一言开关",
            "修改；手机端菜单字体样式修改",
            "修改；搜索框阴影修改",
            "修改；动画优化",
            "重构；所有提示样式修改"
        ]
    },
    {
        time: "2021-7-5",
        log: [
            "新增；新增当前时间显示",
            "新增；新增天气获取",
            "新增；网址列表后端已完成",
            "修改；消息提示框现在可以进行关闭",
            "重构；API重构已上线"
        ]
    },
    {
        time: "2021-6-28",
        log: [
            "新增；新增重置配置功能",
            "修复；修复了一言API加载失败问题",
            "修复；修复了搜索结果不消失的问题",
            "重构；介绍重写",
            "重构；菜单重写"
        ]
    },
    {
        time: "2021-6-24",
        log: [
            "新增；搜索结果支持上下键切换啦！",
            "重构；搜索引擎回调重写，性能优化MAX提升"
        ]
    },
    {
        time: "2021-6-21",
        log: [
            "修改；元素获取代码优化",
            "修复；搜索特殊字符无效修复",
            "修复；自定义壁纸不显示图片信息修复"
        ]
    },
    {
        time: "2021-6-4",
        log: [
            "修改；移除了毛玻璃，优化流畅体验",
            "修改；部分代码更新",
            "修改；自适应更新",
            "修复；壁纸修复",
            "重构；后端更换为Node.js"
        ]
    },
    {
        time: "2021-1-19",
        log: [
            "新增；全端自适应完成",
            "新增；菜单新增关闭按钮",
            "新增；版权与图片信息文字位置更改",
            "新增；版权与图片信息文字可开关",
            "修改；数据结构代码优化"
        ]
    },
    {
        time: "2020-12-27",
        log: ["新增；随机切换壁纸保存"]
    },
    {
        time: "2020-12-22",
        log: [
            "新增；搜索功能已完善",
            "新增；搜索框右侧新增图标",
            "新增；搜索框聚焦效果增加",
            "新增；背景外链链接存储",
            "新增；搜索结果动画增加",
            "新增；点击搜索功能",
            "修复；背景切换修复",
            "修复；背景接口失效会启用备用壁纸"
        ]
    }
];
