import data from "@/data/data";
import backgroundUtil from "@/utils/backgroup";
import hitokoto from "@/utils/hitokoto";
import themeColor from "@/utils/themeColor";
import local from "@/utils/localData/local";
import notify from "@/utils/notify/notify";

export default function (notifyType: boolean) {
    // 部分数据不需要清除
    let updateStorage = data.saveData.updateStorage;
    let gitee = data.saveData.gitee;

    // 深拷贝数据
    data.saveData = JSON.parse(JSON.stringify(data.saveDataCache));

    // 还原备份数据
    data.saveData.updateStorage = updateStorage;
    data.saveData.gitee = gitee;

    // bing 壁纸获取
    backgroundUtil.getBing();
    // 一言获取
    hitokoto();
    // 保存新数据
    local.save();
    // 主题色修改
    themeColor.change();

    if (notifyType) {
        notify("重置成功", 1);
    }
}
